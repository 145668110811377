@import "variables";

.services {
  position: relative;
  margin-bottom: 150px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 100px;
  }

  &__title {
    font-family: $font;
    font-size: 46px;
    font-weight: 500;
    color: $color-1;
    margin-top: 80px;
    margin-bottom: 70px;

    @media screen and (max-width: 1024px) {
      margin-top: 40px;
      margin-bottom: 25px;
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    width: 33.33333333333333%;
    height: 300px;
    border: solid 1px $color-3;
    padding: 25px 35px;

    &::after {
      position: absolute;
      left: 35px;
      bottom: 33px;
      transform: rotate(-90deg);
      content: '';
      width: 21px;
      height: 21px;
      object-fit: contain;
      cursor: pointer;
      background-image: url(./../img/arrow-down-red.svg);
      background-repeat: no-repeat;
    }

    @media screen and (max-width: 1024px) {
      padding: 15px 40px 15px 15px;
      margin-bottom: -1px;

      &::after {
        left: initial;
        bottom: initial;
        right: 15px;
        top: 25px;
        background: url('./../img/plus.svg') no-repeat center right;
      }
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 100%;
    }

    &_main {
      font-family: $font;
      font-size: 4rem;
      font-weight: 500;
      color: $color-2;
      line-height: 1.2;

      @media screen and (max-width: 1300px) {
        font-size: 3rem;
      }

      @media screen and (max-width: 1024px) {
        font-size: 2.4rem;
      }
    }

    &_hover {
      position: absolute;
      cursor: pointer;
      padding: 25px 35px;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: $color-2;
      color: $color-white;
      z-index: 2;
      transition: all .2s ease-in-out;
      visibility: hidden;
    }

    &_title {
      margin: 0;
      font-family: $font;
      font-size: 4rem;
      font-weight: 500;
      line-height: 1.2;
      color: $color-white;

      @media screen and (max-width: 1300px) {
        font-size: 3rem;
      }
    }

    &_text {
      margin: 30px 0 0;
      font-size: 1.4rem;
      line-height: 1.5;
      color: $color-white;
    }

    ul {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }

    &:hover {
      transition: all .2s ease-in-out;

      .services__item_hover {
        visibility: visible;

        @media screen and (max-width: 1024px) {
          visibility: hidden;
        }
      }
    }
  }

}