@import "variables";

@media screen and (max-width: 1024px) {
  #contacts.section {
    padding: 0 !important;
  }
}

.contacts {
  display: flex;
  margin-top: 100px;
  z-index: 2;

  &__desktop {
    display: block;
  }

  &__mobile {
    display: none;

    &-title {
      display: flex;
      align-items: center;
      height: 80px;

      span {
        font-size: 2.1rem;
        color: $color-2;
        padding: 0 25px;
        text-align: center;
        width: 100%;
        background-color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 81px;
      }

      span.active {
        color: $color-white;
        background-color: $color-2;
      }
    }

    &-text {
      position: relative;

      div {
        padding: 15px;
        width: 100%;
        background-color: $color-2;
        display: none;

        p {
          font-size: 1.8rem;
          line-height: 1.67;
          opacity: 0.7;
          color: $color-white;
        }
      }

      div.active {
        display: block;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    margin-top: 0;

    &__desktop {
      display: none;
    }

    &__mobile {
      display: block;
    }
  }

  &__info {
    position: relative;
    width: 54%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    &::after {
      position: absolute;
      content: '';
      background-image: url(./../img/pattern-3.png);
      width: 150%;
      height: 306px;
      left: -55%;
      top: -126px;
      z-index: -1;

      @media screen and (max-width: 1024px) {
        top: -30px;
      }
    }

    h2 {
      font-family: $font;
      font-size: 46px;
      font-weight: 500;
      color: $color-1;
      margin-bottom: 74px;
      margin-left: 70px;

      @media screen and (max-width: 1024px) {
        margin-left: 15px;
        margin-bottom: 32px;
      }
    }
  }

  &__map {
    width: 46%;
    min-height: 100%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    &-moscow,
    &-piter {
      display: none;
    }

    &-active {
      display: block;
      height: 100%;
    }
  }

  &__block {
    position: relative;
    width: 100%;
    padding: 70px;

    @media screen and (max-width: 1024px) {
      padding: 10px;
    }

    &:hover {
      cursor: pointer;
      background-color: $color-3;
    }

    h4 {
      font-family: $font2;
      font-size: 32px;
      margin: 0 0 20px;
      color: $color-2;
    }

    p {
      opacity: 0.7;
      font-family: $font2;
      font-size: 20px;
      line-height: 1.5;
      color: $color-1;
      margin: 0;
    }

    &-active {
      color: $color-white;
      background-color: $color-2 !important;
      height: 100%;

      p,
      h4 {
        color: $color-white;
      }

      &::after {
        position: absolute;
        right: 30px;
        top: 48%;
        transform: rotate(-90deg);
        content: '';
        width: 21px;
        height: 21px;
        object-fit: contain;
        cursor: pointer;
        background-image: url(./../img/arrow-down.svg);
        background-repeat: no-repeat;
      }
    }
  }

  iframe {
    filter: grayscale(100%);
    height: 100%;

    @media screen and (max-width: 1024px) {
      height: 375px;
    }
  }
}