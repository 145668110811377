@import "variables";

.copyright {
  display: flex;
  justify-content: center;
  margin: 40px 40px 20px;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  a,
  span {
    font-size: 16px;
    color: $color-1;
    text-decoration: none;
  }

  span {
      display: block;
      margin: 22px;
  }
}