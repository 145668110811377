@import "variables";

.slide__about {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  padding-top: 100px;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    padding-top: 50px;
  }

  &-text {
    width: 47%;
    padding-right: 3%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    h2 {
      margin-bottom: 24px;
      @media screen and (max-width: 1024px) {
        font-size: 3.6rem;
      }
    }

    h3 {
      margin-bottom: 14px;
      margin-top: 36px;
    }
  }

  &-fact {
    display: flex;
    flex-flow: wrap;
    align-content: flex-start;
    width: 50%;
    margin-top: 50px;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    &-info {
      display: flex;
      padding: 50px;
      flex-flow: column;
      width: 50%;
      border: solid 1px $color-3;
      height: 50%;

      @media screen and (max-width: 1024px) {
        padding: 10px;
        border: 0;
      }

      span:first-child {
        display: block;
        font-size: 6.5rem;
        font-weight: 500;
        color: $color-2;
        font-family: $font;

        @media screen and (max-width: 1024px) {
          font-size: 5rem;
        }
      }

      span:last-child {
        display: block;
        font-size: 1.6rem;
        line-height: 1.56;
        color: $color-2;
      }
    }

  }
}

.about {
  position: relative;
  background-color: $color-white;

  &__facts {
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 100px;
    z-index: 2;

    @media screen and (max-width: 1024px) {
      padding-bottom: 0;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      background: url("./../img/group-41.svg") no-repeat right top;
      width: 100%;
      height: 100%;
      max-width: 411px;
      max-height: 411px;
      z-index: 1;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &::after {
    position: absolute;
    content: '';
    background-image: url(./../img/pattern-1.png);
    width: 40%;
    height: 224px;
    right: 0;
    bottom: 0;
    z-index: 1;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
