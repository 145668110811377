@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:500|Open+Sans:400,700&subset=cyrillic');

@import "normalize";
@import "main";
@import "services";
@import "team";
@import "header";
@import "about";
@import "contacts";
@import "copyright";
@import "partners";
@import "variables";


html {
  font-size: 10px;
}
//@media screen and (max-width: 1024px) {
//  html {
//    font-size: 9px;
//  }
//}

div, 
img, 
p, 
span {
  box-sizing: border-box;
  color: $color-1;
  font-family: $font2;
}

p {
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
}

h2 {
  font-size: 4.6rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: $font;
}

h3 {
  font-family: $font2;
  font-size: 2.2rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
}

ul {
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  list-style-image: url(./../img/shape.svg);
  padding: 0 0 0 25px;

  &.white {
    list-style-image: url(./../img/shape-white.svg);
  }

  li {
    margin-bottom: 20px;
  }
}

body {
  margin: 0;
  padding: 0;
  color: $body-color;
  background-color: $color-white;
}

a {
  font-family: $font2;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-2;
}

.fullpage-wrapper {
  max-width: 1370px;
  margin: 0 auto !important;
}

.section {
  position: relative;
  max-width: 1370px;
  padding: 0 35px !important;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;

  @media screen and (max-width: 1024px) {
    padding: 0 15px !important;
  }
}

#fullpage {
  position: relative;
  display: flex;
  flex-flow: column;
  transition: all .3s ease-in;
  z-index: 2;
  background-color: $color-white;
}
#fullpage.content__hide {
  opacity: 0;
}



#burgerBtn {
  border-top: 2px solid $color-1;
  height: 25px;
  width: 30px;
  box-sizing: border-box;
  position: absolute;
  z-index: 30;
  right: 20px;
  top: 15px;
  cursor: pointer;
  transition: all .3s ease-in;

  &:before {
    content:"";
    display:block;
    position:absolute;
    height:2px;
    width:30px;
    left:0;
    background:$color-1;
    top:10px;
    transition: all .3s ease-in;
  }

  &:after {
    content:"";
    display:block;
    position:absolute;
    height:2px;
    width:30px;
    left:0;
    background:$color-1;
    bottom:0;
    transition: all .3s ease-in;
  }
}

#nav {
  background-color: $color-white;
  position: absolute;
  top: 61px;
  left: 0;
  margin: 0;
  width: 100%;
  height: 0;
  padding: 0;
  z-index: 1;
  opacity: 0;
  transition: all .3s ease-in;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  visibility: hidden;
}

.menu__show #nav {
  opacity: 1;
  height: 100vh;
  visibility: visible;
}

.menu__mobile {
  @media screen and (min-width: 1024px) {
    display: none;
  }
  &-item {
    display: block;
    width: 100%;
    padding: 20px;
    opacity: 0.8;
    font-size: 18px;
    color: $color-1;
    text-decoration: none;
  }

  &-phone {
    position: relative;
    text-align: center;
    font-size: 18px;
    width: 100%;
    margin-bottom: 32px;

    span {
      font-family: $font2;
      color: $color-1;
    }

    a {
      font-family: $font2;
      display: initial;
      color: $color-2;
      font-size: 18px;
      text-decoration: none;
    }
  }

  &-copyright {
    border-top: 1px solid $color-3;
    height: 57px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 0 15px;
    font-size: 14px;
    color: $color-1;

    a {
      color: $color-1;
      text-decoration: none;
    }
  }
}

.menu__show #burgerBtn {
  border-color: transparent;

  &:before {
    transform: rotate(45deg);
    width:33px;
    left:-2px;
  }

  &:after {
    transform: rotate(135deg);
    bottom:11px;
    width:33px;
    left:-2px;
  }
}








#popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 50;
  transition: all .3s ease-in;

  .popup__content {
    position: relative;
    padding: 20px;
    background-color: $color-2;
    height: calc(100% - 64px);
    color: white;
    z-index: 99;
    margin: 32px 15px;
    overflow: auto;

    p,
    ul {
      color: white;
      font-size: 1.8rem;
    }

    .services__item_title {
      font-size: 3.2rem;
    }
  }

  &.popup__show {
    visibility: visible;
  }

  .popup__close {
    position: absolute;
    right: 10px;
    top: 10px;
    background: url("./../img/x.svg") no-repeat center center;
    width: 12px;
    height: 12px;
    padding: 10px;
  }

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0.7;
    background-color: $color-1;
  }
}

.disable__scroll {
  overflow: hidden;
}


.mobile-phone {
  position: fixed;
  width: 60px;
  height: 60px;
  display: none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: $color-white;
  z-index: 2;
  right: 15px;
  bottom: 15px;

  @media screen and (max-width: 1024px) {
    display: block;
  }

  &::after {
    position: absolute;
    left: 20px;
    top: 20px;
    content: '';
    width: 22px;
    height: 22px;
    object-fit: contain;
    cursor: pointer;
    background-image: url(./../img/phone.svg);
    background-repeat: no-repeat;
  }
}

.mobile-lang {
  position: absolute;
  right: 50px;
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }

  a {
    font-size: 18px;
    color: $color-2;
    text-decoration: none;
  }

  .hide {
    display: none;
  }
}

.button {
  background-color: $color-2;
  padding: 25px 40px;
  color: $color-white;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
}
