@import "variables";

.team {
  position: relative;
  padding-top: 200px;
  padding-bottom: 10px;
  background-color: $color-white;

  @media screen and (max-width: 1024px) {
    padding-bottom: 30px;
    padding-top: 10px;
  }

  h2 {
    margin-top: 120px;
    z-index: 2;
    position: relative;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.78;
    text-align: center;
    letter-spacing: normal;
    color: $color-1;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: url('./../img/shark.svg') no-repeat top left;
    width: 100%;
    height: 100%;
    z-index: 1;

    @media screen and (max-width: 1024px) {
      bottom: -50px;
      background-size: contain;
    }
  }

  &__wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media screen and (max-width: 1024px) {
      // flex-wrap: nowrap;
      // overflow-y: scroll;
    }
  }

  &__item {
    width: calc(50% - 50px);
    height: 100%;
    box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.08);
    background-color: $color-white;
    margin-bottom: 32px;
    margin-right: 50px;
    z-index: 2;

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-right: 0;
    }


    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }

    &_info {
      padding: 30px 30px;
      text-align: center;
      background-color: $color-white;

      @media screen and (max-width: 1024px) {
        padding: 20px 60px;
      }

      &_hover {
        position: absolute;
        cursor: pointer;
        padding: 25px 35px;
        left: 0;
        top: 0;
        width: 100%;
        min-height: 100%;
        background-color: $color-2;
        color: $color-white;
        z-index: 2;
        transition: all .2s ease-in-out;
        visibility: hidden;

        @media screen and (max-width: 1024px) {
          display: none;
        }
    
        p {
          margin: 12px 0 0;
          font-size: 1.4rem;
          line-height: 1.5;
          color: $color-white;
        }
      }

      &_title {
        margin: 0 !important;
        font-family: $font;
        font-size: 4rem !important;
        font-weight: 500 !important;
        line-height: normal !important;
        color: $color-white;
      }
    }

    &_wrap {
      position: relative;

      &:hover {
        transition: all .2s ease-in-out;
  
        .team__item_info_hover {
          visibility: visible;
  
          @media screen and (max-width: 1024px) {
            visibility: hidden;
          }
        }
      }
    }

    &_position {
      opacity: .7;
      font-family: $font2;
      font-size: 1.6rem;
      line-height: normal;
      color: $color-1;
      margin: 0 0 6px;

      @media screen and (max-width: 1024px) {
        font-size: 1.4rem;
      }
    }

    &_name {
      font-family: $font2;
      font-size: 1.8rem;
      line-height: normal;
      color: $color-1;
      margin: 0;

      @media screen and (max-width: 1024px) {
        font-size: 1.5rem;
      }
    }
  }
}
