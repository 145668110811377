@import "variables";

header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 0 35px;
  box-sizing: border-box;
  background-color: $color-white;
  z-index: 3;
  top: 0;

  @media screen and (max-width: 1024px) {
    height: 61px;
    padding: 0 15px;
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: $color-3;
  }

  .logo {
    &__img {
      width: 210px;
      height: 55px;
      object-fit: contain;

      @media screen and (max-width: 1024px) {
        width: 150px;
      }
    }
  }

  .menu {
    display: flex;
    height: 100%;
    align-items: center;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    a {
      padding: 18px;
      opacity: 0.8;
      font-family: $font2;
      font-size: 1.6rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $color-1;
      text-decoration: none;
    }

    &__lang {
      color: $color-2 !important;
      padding-right: 30px !important;
    }

    .hide {
      display: none;
    }

    &__phone {
      position: relative;
      padding: 0 0 0 30px;
      height: 100%;
      display: flex;
      align-items: center;

      span {
        font-size: 1.6rem;
      }

      a {
        color: $color-2;
        padding: 0 0 0 10px;
      }

      &::after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: $color-3;
      }
    }
  }
}


