@import "variables";

.partners {
  position: relative;
  display: flex;
  margin-bottom: 200px;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  &__left {
    width: 300px;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    &::after {
      position: absolute;
      content: '';
      background-image: url(./../img/pattern-1.png);
      width: 336px;
      height: 224px;
      left: -65px;
      top: 50px;
      z-index: -1;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &__right {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__item {
    width: 25%;
    padding: 25px 40px;
    text-align: center;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 15px 20px;
    }

    img {
      width: 100%;
      max-height: 80px;
      object-fit: contain;
      filter: grayscale(100%);

      @media screen and (max-width: 1024px) {
        width: 200px;
      }
    }

    &:hover {
      img {
        filter: none;
      }
    }
  }
}


.siema {
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    height: 80px;
  }

  img {
    max-width: 150px;
    max-height: 80px;
    filter: grayscale(100%);
    display: none;

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  div:first-child {
    display: flex;
    align-items: center;
  }
}