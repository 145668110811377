@import "variables";

.slide__main {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}

.main__content {
  display: flex;
  width: 100%;
  margin-bottom: 10%;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1;

  .slide__main--404 & {
    justify-content: center;
    flex-wrap: wrap;
    flex-flow: column;
    align-items: center;
    margin-bottom: 35%;
  }

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    margin-bottom: 35%;

    .slide__main--404 & {
      margin-bottom: 60%;
      justify-content: center;
    }
  }
}

.main__title {
  font-family: $font;
  max-width: 690px;
  font-size: 6.5rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-1;
  margin: 0;

  .slide__main--404 & {
    font-size: 2.8rem;
  }

  @media screen and (max-width: 1024px) {
    font-size: 3.8rem;

    .slide__main--404 & {
      font-size: 2.8rem;
    }
  }
  @media screen and (max-width: 320px) {
    font-size: 4rem;
  }
}

.main__img {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(./../img/cry.svg);
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  transition: background-position 0.25s;

  .slide__main--404 & {
    top: -150px;
    transform: rotate(180deg);
  }

  @media screen and (max-width: 1024px) {
    top: -100px;
    background-size: contain;
  }

  @media screen and (max-width: 320px) {
    top: -60px;
  }

  img {
    width: 648px;
    height: 578px;
    object-fit: contain;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 100%;
    }
  }
}

.main__pattern {
  position: fixed;
  background-image: url(./../img/pattern-2.png);
  width: 60%;
  height: 100%;
  right: 2%;
  z-index: -1;

  @media screen and (max-width: 1024px) {
    width: 83%;
    right: 0;
  }
}

.main__button-wrap {
  position: relative;
  margin: 0 0 40px;

  @media screen and (max-width: 1024px) {
    margin: 0 auto;
    padding-left: 140px;
    padding-top: 80px;
  }
}
.main__button {
  position: relative;
  font-family: $font2;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-2;
  cursor: pointer;

  &::before {
    position: absolute;
    left: -87px;
    top: -30px;
    content: '';
    width: 75px;
    height: 75px;
    background-color: $color-2;
    cursor: pointer;
  }

  &::after {
    position: absolute;
    left: -60px;
    top: 0px;
    content: '';
    width: 20px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;
    background-image: url(./../img/arrow-down.svg);
  }
}

.title-404 {
  position: absolute;
  bottom: 46px;
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  font-family: $font2;
}
